.nav-item:hover{
  transition: all 1s ease;
  background: linear-gradient(80deg, rgba(217, 255, 3, 0.432), rgba(0, 119, 255, 0.329), rgba(255, 0, 0, 0.377));
}

.App
.nav-link:focus{
  color: black;
}

.nav-item .nav-link{
  color: black;
}

.navbar .navbar-toggler{
  border: none;
}

.navbar .navbar-toggler:focus{
  box-shadow: none;
}

.breadcrumb-item{
  position: relative;
  padding: 3px;
}

.breadcrumb-item+.breadcrumb-item::before{
  content: var(--bs-breadcrumb-divider, "") !important;
}

.breadcrumb-item:nth-child(2), .breadcrumb-item:nth-child(3), .breadcrumb-item:nth-child(4){
  margin-left: 25px;
}

.breadcrumb-item > a{
  text-shadow: -2px 2px 3px black;
}

.breadcrumb-item, .breadcrumb-item.active{
  background: linear-gradient(120deg, rgba(0, 119, 255, 0.562), rgba(0, 119, 255, 0.562));
}

.breadcrumb-item::after{
  content: '';
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  left: -14px;
  border-top: 15px solid rgba(0, 119, 255, 0.562);
  border-bottom: 14px solid rgba(0, 119, 255, 0.562);
  border-left: 14px solid transparent;
  border-right: 0px solid transparent;
}

.breadcrumb-item:nth-child(2)::before, .breadcrumb-item:nth-child(3)::before, .breadcrumb-item:nth-child(4)::before{
  right: -22px;
}

.breadcrumb-item::before{
  content: '';
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  right: -14px;
  border-top: 15px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid rgba(0, 119, 255, 0.562);
  border-right: 0px solid transparent;
}

.tema{
  cursor: pointer;
  filter: drop-shadow(-1px 1px 2px black);
}

.tema1:hover{
  animation: tema2 0.8s infinite ease;
}

.tema2:hover{
  animation: tema1 0.8s infinite ease;
}

.nav-link.disabled{
  color: rgb(253, 124, 124) !important;
}

@keyframes tema1{
  to{
    transform: translateX(10px);
  }
  from{
    transform: translateX(0px);
  }
}

@keyframes tema2{
  to{
    transform: translateX(-10px);
  }
  from{
    transform: translateX(0);
  }
}

.unidad:hover{
  background-color: #0A2E48;
  color: white;
}


.container-material{
  border-right: 1px solid black;
  width: 250px;
  height: auto;
  background: #0A2E48;
}

.titulo-material{
  border-bottom: 1px solid black;
  background: white;
}

.body-material{
  height: 100vh;
}

/*estilos registrar temas*/
.container-tema{
  border-right: 1px solid black;
  width: 300px;
  height: auto;
  background: #0A2E48;
}

.text-respu{
  width: 25%;
}

/*Estilos crear torneos*/
.card-crear-torneo{
  overflow: hidden;
}

.linea{
  position: absolute;
  z-index: 10;
  display: block;
}

.card-crear-torneo .linea-top{
  animation:linea-top 3s ease-in-out infinite;
  width: 100%;
  height: 3px;
  top: 0;
  left: -100%;
  background: linear-gradient(90deg, transparent, rgba(255, 0, 0, 0.7), rgba(0, 119, 255, 0.7), rgba(217, 255, 3, 0.7), rgba(217, 255, 3, 0.7));
}

.card-crear-torneo .linea-right{
  animation:linea-right 3s ease-in-out infinite;
  animation-delay: 1.5s;
  width: 3px;
  height: 100%;
  right: 0;
  top: -100%;
  background: linear-gradient(180deg, transparent, rgba(255, 0, 0, 0.7), rgba(0, 119, 255, 0.7), rgba(217, 255, 3, 0.7), rgba(217, 255, 3, 0.7));
}

.card-crear-torneo .linea-bottom{
  animation:linea-bottom 3s ease-in-out infinite;
  animation-delay: 3s;
  width: 100%;
  height: 3px;
  bottom: 0;
  right: -100%;
  background: linear-gradient(-90deg, transparent, rgba(255, 0, 0, 0.7), rgba(0, 119, 255, 0.7), rgba(217, 255, 3, 0.7), rgba(217, 255, 3, 0.7));
}

.card-crear-torneo .linea-left{
  animation:linea-left 3s ease-in-out infinite;
  animation-delay: 4.5s;
  width: 3px;
  height: 100%;
  bottom: 100%;
  left: 0;
  background: linear-gradient(0deg, transparent, rgba(255, 0, 0, 0.7), rgba(0, 119, 255, 0.7), rgba(217, 255, 3, 0.7), rgba(217, 255, 3, 0.7));
}
/*Estilos para la vista de los torneos*/
.card-torneo{
  overflow: hidden;
}

.linea{
  position: absolute;
  z-index: 10;
  display: block;
}

/*animacion verde*/
.animacion-verde .linea-top{
  animation:linea-top 3s ease-in-out infinite;
  width: 100%;
  height: 3px;
  top: 0;
  left: -100%;
  background: linear-gradient(90deg, transparent, green);
}

.animacion-verde .linea-right{
  animation:linea-right 3s ease-in-out infinite;
  animation-delay: 1.5s;
  width: 3px;
  height: 100%;
  right: 0;
  top: -100%;
  background: linear-gradient(180deg, transparent, green);
}

.animacion-verde .linea-bottom{
  animation:linea-bottom 3s ease-in-out infinite;
  animation-delay: 3s;
  width: 100%;
  height: 3px;
  bottom: 0;
  right: -100%;
  background: linear-gradient(-90deg, transparent, green);
}

.animacion-verde .linea-left{
  animation:linea-left 3s ease-in-out infinite;
  animation-delay: 4.5s;
  width: 3px;
  height: 100%;
  bottom: 100%;
  left: 0;
  background: linear-gradient(0deg, transparent, green);
}

/*animacion azul*/
.animacion-azul .linea-top{
  animation:linea-top 3s ease-in-out infinite;
  width: 100%;
  height: 3px;
  top: 0;
  left: -100%;
  background: linear-gradient(90deg, transparent, blue);
}

.animacion-azul .linea-right{
  animation:linea-right 3s ease-in-out infinite;
  animation-delay: 1.5s;
  width: 3px;
  height: 100%;
  right: 0;
  top: -100%;
  background: linear-gradient(180deg, transparent, blue);
}

.animacion-azul .linea-bottom{
  animation:linea-bottom 3s ease-in-out infinite;
  animation-delay: 3s;
  width: 100%;
  height: 3px;
  bottom: 0;
  right: -100%;
  background: linear-gradient(-90deg, transparent, blue);
}

.animacion-azul .linea-left{
  animation:linea-left 3s ease-in-out infinite;
  animation-delay: 4.5s;
  width: 3px;
  height: 100%;
  bottom: 100%;
  left: 0;
  background: linear-gradient(0deg, transparent, blue);
}

/*animacion rojo*/
.animacion-rojo .linea-top{
  animation:linea-top-stop 2s ease-in-out forwards;
  width: 100%;
  height: 3px;
  top: 0;
  left: -100%;
  background: linear-gradient(90deg, red, red);
}

.animacion-rojo .linea-right{
  animation:linea-right-stop 2s ease-in-out forwards;
  animation-delay: 2s;
  width: 3px;
  height: 100%;
  right: 0;
  top: -100%;
  background: linear-gradient(180deg, red, red);
}

.animacion-rojo .linea-bottom{
  animation:linea-bottom-stop 2s ease-in-out forwards;
  animation-delay: 3.7s;
  width: 100%;
  height: 3px;
  bottom: 0;
  right: -100%;
  background: linear-gradient(-90deg, red, red);
}

.animacion-rojo .linea-left{
  animation:linea-left-stop 2s ease-in-out forwards;
  animation-delay: 5.4s;
  width: 3px;
  height: 100%;
  bottom: 100%;
  left: 0;
  background: linear-gradient(0deg, red, red);
}

@keyframes linea-top {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes linea-right {
  0% {
    top: -100%;
  }
  100% {
    top: 100%;
  }
}

@keyframes linea-bottom {
  0% {
    right: -100%;
  }
  100% {
    right: 100%;
  }
}

@keyframes linea-left {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 100%;
  }
}

@keyframes linea-top-stop {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes linea-right-stop {
  0% {
    top: -100%;
  }
  100% {
    top: 0%;
  }
}

@keyframes linea-bottom-stop {
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}

@keyframes linea-left-stop {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0%;
  }
}

/*Estilos para el pdf*/
.pdf-max{
  height: 555px;
}

.p-dialog-maximized .pdf-max{
  height: 85vh;
}

/*Estilos para los torneos*/
.circles-card-torneo{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles-card-torneo li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  animation: animate-card-torneo 25s linear infinite;
  bottom: -150px;
}

.circles-card-torneo li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
  background: rgba(217, 255, 3, 0.432);
}

.circles-card-torneo li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
  background: rgba(0, 119, 255, 0.329);
}

.circles-card-torneo li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
  background: rgba(255, 0, 0, 0.377);
}

.circles-card-torneo li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
  background: rgba(255, 0, 0, 0.377);
}

.circles-card-torneo li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
  background: rgba(0, 119, 255, 0.329);
}

.circles-card-torneo li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
  background: rgba(255, 0, 0, 0.377);
}

.circles-card-torneo li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
  background: rgba(217, 255, 3, 0.432);
}

.circles-card-torneo li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
  background: rgba(0, 119, 255, 0.329);
}

.circles-card-torneo li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
  background: rgba(255, 0, 0, 0.377);
}

.circles-card-torneo li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
  background: rgba(0, 119, 255, 0.329);
}

@keyframes animate-card-torneo {
  0%{
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100%{
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

/* estilos para un marquee */
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee span {
  transition: all 2s ease;
  display: inline-block;
  padding-left: 100%;
  animation: marquee 25s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
}

/* estilos para resaltar las letras solo por un momento */
.resaltadoPorMomento{
  transition: all 400ms ease;
  animation: resaltado 3s linear forwards;
}

@keyframes resaltado {
  from { color: rgb(117, 241, 189);}
  to { color: black;}
}

@media(min-width: 828px){
  .contactenos{
    display: flex;
    align-items: end;
  }
}

@media(max-width: 680px){
  /*estilos curso*/
  .container-curso{
    display: flex;
    flex-direction: column;
  }
  
  .container-tema{
    width: 100%;
  }

  .titulo-material{
    width: 100%;
    border-bottom: none;
  }

  .container-material{
    width: 100%;
    border-right: none;
  }

  .body-material{
    width: 100%;
    display: flex;
    height: auto;
  }

  .imagen-curso{
    display: none;
  }

  .body-material
  .navbar-nav 
  .dropdown-menu.show{
    position: relative !important;
    transform: translate3d(0, 0, 0) !important;
  }

  /*estilos registrar temas*/
  .input-respu{
    width: 70%;
  }

  .input-tema-eliminar{
    margin-top: 8px;
  }

  .tabla-tiempos{
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
  }

  .formulario-tablas{
    width: 100% !important;
  }
}

/*Estilos para la planilla*/
.planilla > button{
  border-radius: 50%;
  box-shadow: 0 0 3px black;
}

.planilla > ul > li > a{
 text-decoration: none;
 color: white;
}