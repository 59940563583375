/* Estilos a la barra scroll */
html::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}

html::-webkit-scrollbar-corner{
  background: red;
}

html::-webkit-scrollbar-thumb{
  background: linear-gradient(80deg, rgb(47, 157, 247), white);
}

html::-webkit-scrollbar-thumb:hover{
  border-radius: 4px;
  background: linear-gradient(80deg, rgb(47, 157, 247), white, red);
}

.App {
  text-align: center;
  height: 100vh;
}

.item-principal:hover{
  background: none !important;
}

.item-principal:hover .link-principal{
  color: black !important;
}

.nombre-logo{
  opacity: 0;
  visibility: hidden;
}

.logo-navbar:hover .nombre-logo{
  transition: all 2s ease;
  opacity: 1;
  visibility: visible;
}

.logo-navbar:not(:hover) .nombre-logo{
  transition: all 2s ease;
}

.bgColombia {
  background: linear-gradient(80deg, rgba(217, 255, 3, 0.5), rgba(0, 119, 255, 0.3), rgba(255, 0, 0, 0.5));
  bottom:0;
  left:-50%;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #abc7da 50%, rgb(219, 231, 240) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-2;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3 {
  animation-duration:5s;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 30px;
  height: 30px;
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 30px;
  height: 30px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 30px;
  height: 30px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 30px;
  height: 30px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 30px;
  height: 30px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 30px;
  height: 30px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 30px;
  height: 30px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.circles li:nth-child(20){
  left: 20%;
  width: 30px;
  height: 30px;
  animation-delay: 11s;
}


.circles li:nth-child(19){
  left: 5%;
  width: 30px;
  height: 30px;
  animation-delay: 3s;
  animation-duration: 23s;
}

.circles li:nth-child(18){
  left: 65%;
  width: 30px;
  height: 30px;
  animation-delay: 15s;
}

.circles li:nth-child(17){
  left: 35%;
  width: 30px;
  height: 30px;
  animation-delay: 1s;
  animation-duration: 29s;
}

.circles li:nth-child(16){
  left: 60%;
  width: 30px;
  height: 30px;
  animation-delay: 11s;
}

.circles li:nth-child(15){
  left: 70%;
  width: 30px;
  height: 30px;
  animation-delay: 14s;
}

.circles li:nth-child(14){
  left: 30%;
  width: 30px;
  height: 30px;
  animation-delay: 18s;
}

.circles li:nth-child(13){
  left: 45%;
  width: 30px;
  height: 30px;
  animation-delay: 16s;
  animation-duration: 56s;
}

.circles li:nth-child(12){
  left: 15%;
  width: 30px;
  height: 30px;
  animation-delay: 3s;
  animation-duration: 46s;
}

.circles li:nth-child(11){
  left: 80%;
  width: 30px;
  height: 30px;
  animation-delay: 1s;
  animation-duration: 22s;
}

@keyframes animate {

  0%{
      transform: translateY(-1000px) rotate(750deg);
      opacity: 0;
      border-radius: 0%;
      background: rgb(255, 230, 0);
    } 
  50%{
      transform: translateY(-500px) rotate(500deg);
      opacity: 1;
      border-radius: 50%;
      background: rgb(0, 204, 255);
    }
  100%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 50%;
      background: rgb(255, 0, 0);
    }
}

/*Estilo para el calendario*/
.meses:hover{
  transition: all 400ms ease;
  box-shadow: 0 0 6px black;
  z-index: 1;
}

.pago{
  background: #C6FFDD;
  box-shadow: 0 0 1px gray;
}

.debe{
  background: #FFC6C6;
}

.ninguno{
  background: rgb(192, 192, 192);
}

/*estilos para la tabla de afiliados*/
.p-menu{
  width: auto;
}

.p-menu > ol, ul {
  padding-left: 0 !important;
}

.p-menu > ul a {
  text-decoration: none !important;
}

.menu-config:hover{
  background: #f1f1f1;
}

/* estilos primereact*/
.p-dropdown-panel .p-dropdown-items{
  padding: 0;
  margin-bottom: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
  border-bottom: 1px solid rgb(172, 172, 172);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover{
  transition: all 400ms ease;
  background: linear-gradient(80deg, rgba(217, 255, 3, 0.5), rgba(0, 119, 255, 0.3), rgba(255, 0, 0, 0.5));
}

.p-tabview .p-tabview-nav{
  margin-bottom: 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
  color: black;
  border-color: black;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar{
  background-color: black;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link, .p-accordion .p-accordion-header .p-accordion-header-link{
  color: gray;
  text-decoration: none;
}

.p-tabview .p-tabview-panels{
  padding: 0;
}

.chat{
  max-width: 400px;
  z-index: 9999 !important;
  right: 0 !important;
}

/*estilos para interponer por encima del dialog a las ventanas del modal*/
.swal2-container  {
  z-index: 9999999 !important; /* Asegúrate de que sea mayor que el z-index del diálogo */
}