.App
.submit{
  background: #0A2E48;
  color: white;
}

.App
.submit:hover{
  transition: all 400ms ease;
  background: #114468;
  color: white;
}

.link{
  background: none;
  border: none;
  color: rgb(0, 0, 255);
}

.vista-password{
  font-size: 23px;
  margin-left: 5px;
  position: absolute;
  right: 30px;
  color: gray;
  cursor: pointer;
}

.vista-gif{
  width: 11%;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.logo-pantalla-media{
  display: none;
}

/*estilos para input primereact*/
.form-prime .p-inputtext{
 padding: 1px 0;
 padding-left: 8px;
}

.p-password > i{
 display: flex;
 align-items: center;
}

@media(max-width: 680px){
  .form-login-inativo{
    transition: all 400ms ease;
    transform: scale(0);
    display: none;
  }

  .form-registrar-inativo{
    transition: all 400ms ease;
    transform: scale(0);
    display: none;
  }
}
